import React from "react";
import imgProject from "../../assets/img/gesundheitskompotenz.webp";

function Gesundheitskompetenz() {
  return (
    <div className="project-wrapper">
      <div className="container">
        <div className="padding-block-700">
          <div className="project-heading">Gesundheitskompetenz</div>
          <div className="even-areas">
            <img
              className="project-paragraph-img"
              src={imgProject}
              alt="Gesundheitskompetenz"
            ></img>
            <div className="project-paragraph">
              Gesundheitsinformationen werden immer komplexer, aber auch
              leichter zugänglicher. Die Arztpraxis soll Impulsgeber für die
              Förderung und Nutzung der Gesundheitskompetenz werden.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gesundheitskompetenz;
