import React from "react";
import { useMediaQuery } from "react-responsive";
import imgProject from "../../assets/img/foerderung.webp";
import imgProject2 from "../../assets/img/foerderung2.webp";
import postkarte from "../../assets/documents/Postkarte.pdf";
import banner from "../../assets/img/Logo_Banner.webp";
import bannerMobile from "../../assets/img/Logo_Banner_mobile.webp";

function Foerderprojekte() {
  const isMobile = useMediaQuery({
    query: "(max-width: 30em)",
  });

  return (
    <div className="project-wrapper">
      <div className="container">
        <div className="padding-block-700">
          <div className="project-heading">Förderprojekte</div>
          <div className="even-areas">
            <img
              className="project-paragraph-img"
              src={imgProject}
              alt="Förderprojekte"
            ></img>
            <div className="project-paragraph">
              Ideen und Lösungen müssen in der Praxis erprobt werden. Hierzu
              führen wir Projekte durch, um neue Anwendungen oder Prozesse mit
              den Akteuren auszuprobieren
            </div>
          </div>
        </div>
        <div className="project-container">
          <div className="project-heading" data-type="cm">
          Projekt: „Digitaler Praxisfußabdruck in der Lausitz“
            (digiPLZ)
          </div>
          <p className="project-text">
            Wir – der Ärztenetz Südbrandenburg e.V. – sind Teil des{" "}
            <a
              href="https://www.gesunde-lausitz.de/de/projekte.html"
              className="link"
              target="_blank"
              rel="noreferrer noopener"
            >
              WIR! - Bündnisses com(m) 2020
            </a>{" "}
            für kommunale Innovationen. Das Verbundvorhaben „digiPLZ“ verfolgt
            das Ziel, durch Recherchen, Analysen und Erhebungen, einen Digitalen
            Praxisfußabdruck für die Lausitz zu erstellen, die
            Gesundheitsversorgung aktiv zu gestalten und im Strukturwandel zu
            stärken.
          </p>
          <br />
          <div className="project-sub-heading">Darum digiPLZ</div>
          <p className="project-text">
            Die digitale Transformation im Gesundheitswesen ist mehr als die
            Schaffung von digitaler Infrastruktur für die administrative
            Verwaltung und Unterstützung von Praxisabläufen. Bisher liegen nur
            allgemeine Statistiken und Erhebungen zum Digitalisierungsgrad oder
            von Abrechnungs- und Zulassungsdaten neuer Anwendungen vor. Aktuelle
            Ergebnisse finden Sie im PraxisBarometer der{" "}
            <a
              href="https://www.kbv.de/html/praxisbarometer.php"
              className="link"
              target="_blank"
              rel="noreferrer noopener"
            >
              Kassenärztlichen Bundesvereinigung
            </a>{" "}
            .
          </p>
          <br />
          <div className="even-areas" data-type="fp">
            <div>
              <div className="project-sub-heading">
                So setzen wir das Projekt um
              </div>
              <p className="project-text">
                Wir führen derzeit in Kooperation mit der{" "}
                <a
                  href="https://www.b-tu.de/"
                  className="link"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Brandenburgischen technischen Universität Cottbus/Senftenberg
                  (BTU)
                </a>{" "}
                eine Online-Befragung durch. Unsere Erhebung richtet sich an die
                ambulant tätigen Zahn(arzt)-Praxen und Apotheken für den
                Brandenburger und den Sächsischen Teil der Lausitz. Damit
                stellen wir die am Veränderungsprozess beteiligten Akteure in
                den Mittelpunkt, um deren Ausgangssituation und
                Nutzungsverhalten kennenzulernen, sowie praxisindividuelle
                Besonderheiten auszuwerten und deren Kommunikationsaktivitäten
                zu ermitteln.
              </p>
              <br />
              <div className="project-sub-heading">So geht´s weiter</div>
              <p className="project-text">
                Die Ergebnisse aus dem Verbundvorhaben bilden einen wesentlichen
                Grundstein für die Entwicklung kleinräumiger
                Versorgungskennzahlen in Hinblick auf Struktur- und
                Prozessinnovationen. Jeder Fußabdruck zählt! Wir freuen uns,
                wenn sie sich der Umfrage anschließen und aktiv an der
                Gestaltung der Gesundheitsversorgung der Lausitz beteiligen.
              </p>
            </div>
            <img
              className="project-paragraph-img"
              src={imgProject2}
              alt="forderprojekt"
              data-type="cm2"
            ></img>
          </div>
          <div className="project-sub-heading">Ihr Kontakt zu uns</div>
          <p className="project-text">
            Sie möchten sich an der Umfrage beteiligen?{" "}
            <a
              href="https://www.b-tu.de/umfragen/index.php/828153?newtest=Y"
              className="link"
              target="_blank"
              rel="noreferrer noopener"
            >
              Hier
            </a>{" "}
            geht’s zum Fragebogen. Für die Beantwortung der Fragen benötigen Sie
            etwa 7 Minuten. Vielen Dank für´s Mitmachen.
          </p>
          <br />
          <div className="project-sub-heading">Projektkoordinatorin</div>
          <p className="project-text">Andrea Trunev</p>
          <p className="project-text">
            E-Mail:{" "}
            <a
              href="mailto:lausitz@an-sb.de"
              className="link"
              target="_blank"
              rel="noreferrer noopener"
            >
              lausitz@an-sb.de
            </a>
          </p>
          <br />
          <a
            className="btn"
            href={postkarte}
            target="_blank"
            rel="noreferrer noopener"
          >
            Postkarte anzeigen
          </a>
          <div className="project-banner">
            <img
              className="project-banner-img"
              src={isMobile ? bannerMobile : banner}
              alt="Project Banner"
            ></img>

            <div className="project-banner-text">
              Das Projekt wird vom Bundesministerium für Bildung und Forschung
              gefördert.{" "}
              {isMobile ? (
                <>
                  <br /> <br />
                </>
              ) : (
                <></>
              )}{" "}
              Förderkennzeichen: 03WIR650SA
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}

export default Foerderprojekte;
