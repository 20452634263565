import React from "react";
import imgProject from "../../assets/img/digitalisierung.webp";

function Digitalisierung() {
  return (
    <div className="project-wrapper">
      <div className="container">
        <div className="padding-block-700">
          <div className="project-heading">Digitalisierung</div>
          <div className="even-areas">
            <img
              className="project-paragraph-img"
              src={imgProject}
              alt="Digitalisierung"
            ></img>
            <div className="project-paragraph">
              Digitale Anwendungen in die Versorgungspraxis zu integrieren
              erfordert nicht nur Technik und Infrastruktur, sondern oft auch
              die Anpassung von Arbeitsabläufen und Organisationsprozessen.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Digitalisierung;
