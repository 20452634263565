import React from "react";
import Home from "./pages/Home";
import Network from "./pages/Network";
import News from "./pages/News";
import Projects from "./pages/Projects";

function Main() {
  return (
    <>
      <Home />
      <Network />
      <News />
      <Projects />
    </>
  );
}

export default Main;
