import React, { useEffect, useState, useCallback, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { HashLink } from "react-router-hash-link";
import sliderImg1 from "../assets/img/slider_1.webp";
import sliderImg2 from "../assets/img/slider_2.webp";
import sliderImg3 from "../assets/img/slider_3.webp";
import sliderMobileImg1 from "../assets/img/slider_1_mobile.webp";
import sliderMobileImg2 from "../assets/img/slider_2_mobile.webp";
import sliderMobileImg3 from "../assets/img/slider_3_mobile.webp";

function Home() {
  const isLargeMonitor = useMediaQuery({
    query: "(min-width: 1248px)",
  });

  const sliderList = [
    [sliderImg1, "Wir stehen für ein gemeinsames Netzwerk."],
    [sliderImg2, "Wir stärken die regionale Versorgung."],
    [sliderImg3, "Wir bündeln Interessen, Partner und Ideen."],
  ];

  const sliderListMobile = [
    [sliderMobileImg1, "Wir stehen für ein gemeinsames Netzwerk."],
    [sliderMobileImg2, "Wir stärken die regionale Versorgung."],
    [sliderMobileImg3, "Wir bündeln Interessen, Partner und Ideen."],
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeAutoplay, setActiveAutoplay] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const autoplayRef = useRef();

  const settings = {
    maxItems: 3,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const goTo = useCallback(
    (index, e) => {
      if (!isAnimating) {
        setCurrentIndex(index);
        setIsAnimating(true);

        setTimeout(() => {
          setIsAnimating(false);
        }, settings.speed);
      }
    },
    [isAnimating, currentIndex]
  );

  /* Go Next */
  const goNext = () => {
    goTo(currentIndex >= settings.maxItems - 1 ? 0 : currentIndex + 1);
  };

  /* Go Prev */
  const goPrev = () => {
    goTo(currentIndex <= 0 ? settings.maxItems - 1 : currentIndex - 1);
  };

  /* Play Timer */
  const playTimer = () => {
    setActiveAutoplay(true);
  };

  /* Pause Timer */
  const pauseTimer = () => {
    setActiveAutoplay(false);
  };

  useEffect(() => {
    if (settings.autoplay && activeAutoplay) {
      clearTimeout(autoplayRef.current);
      autoplayRef.current = setTimeout(() => {
        goNext();
      }, settings.autoplaySpeed);
    }
  }, [currentIndex, activeAutoplay, isAnimating]);

  const nextBtn = () => {
    return (
      <div
        className="hr-slider-controls controls-right"
        onMouseEnter={pauseTimer}
        onMouseLeave={playTimer}
        onClick={() => goNext()}
      ></div>
    );
  };

  const prevBtn = () => {
    return (
      <div
        className="hr-slider-controls controls-left"
        onMouseEnter={pauseTimer}
        onMouseLeave={playTimer}
        onClick={() => goPrev()}
      ></div>
    );
  };

  const dots = () => {
    const totalItems = Array.apply(null, Array(settings.maxItems));
    const dotsButtons = totalItems.map((item, index) => {
      return (
        <div
          className={
            index === currentIndex
              ? "hr-slider-dot hr-slider-dot-active"
              : "hr-slider-dot"
          }
          key={index.toString()}
          onMouseEnter={pauseTimer}
          onMouseLeave={playTimer}
          onClick={(e) => goTo(index, e)}
        ></div>
      );
    });
    return <div className="hr-slider-dots">{dotsButtons}</div>;
  };

  const slide = (index) => {
    var sliderItems = sliderListMobile;

    if (isLargeMonitor) {
      sliderItems = sliderList;
    }

    return (
      <span>
        <div className="hr-slider-info">
          <div className="hr-slider-info-text">{sliderItems[index][1]}</div>
        </div>
        <img
          className="hr-slider-img"
          src={sliderItems[index][0]}
          alt="sliderImg"
        />
      </span>
    );
  };

  return (
    <section id="home">
      <div className="hr-slider">
        {prevBtn()}
        {nextBtn()}
        {dots()}
        {slide(currentIndex)}
      </div>
    </section>
  );
}

export default Home;
