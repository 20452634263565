import React, { useState, useEffect } from "react";
import ContactForm from "../../components/Form/ContactForm";
import mailImg from "../../assets/img/contact_mail_img.webp";
import phoneImg from "../../assets/img/contact_phone_img.webp";
import eMailImg from "../../assets/img/contact_email_img.webp";
import { HashLink } from "react-router-hash-link";

function Contact() {
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const ContactFormComponent = () => {
    if (showConfirmation) {
      return (
        <div className="contact-form-wrapper">
          <div className="contact-form-confirmation">
            <p className="fw-bold">Herzlichen Dank für ihre Kontaktanfrage.</p>
            <p>
              Hiermit bestätigen wir die Ankunft dieser über das
              Kontaktformular.
            </p>
            <p>
              Wir werden Sie so schnell wie möglich über die von Ihnen
              angegebene E-Mail kontaktieren.
            </p>
          </div>
          <HashLink to="/" className="btn">
            Zurück zur Startseite
          </HashLink>
        </div>
      );
    } else {
      return <ContactForm AfterSubmitAction={setShowConfirmation} />;
    }
  };

  return (
    <div className="container" data-color="gray">
      <div className="padding-block-500">
        <div className="primary-heading text-left">Kontaktieren Sie uns.</div>
        <div className="contact-info">
          <h3 className="fw-bold">
            Ärzte- und Gesundheitsnetz Südbrandenburg e.V.
          </h3>
          <div className="contact-info-list">
            <div className="contact-info-list-item">
              <img src={eMailImg} alt="E-Mail"></img>
              <a className="link" href="mailto:info@an-sb.de">
                info@an-sb.de
              </a>
            </div>
            <div className="contact-info-list-item">
              <img src={phoneImg} alt="Telefon"></img>
              <p>+49 1523 19 29 494</p>
            </div>
            <div className="contact-info-list-item">
              <img src={mailImg} alt="Adresse"></img>

              <p>Westfalenstraße 2 / 03238 Finsterwalde</p>
            </div>
          </div>
        </div>
        <ContactFormComponent />
      </div>
    </div>
  );
}

export default Contact;
