import React from "react";
import imgProject from "../../assets/img/strukurwandel.webp";

function Strukturwandel() {
  return (
    <div className="project-wrapper">
      <div className="container">
        <div className="padding-block-700">
          <div className="project-heading">Strukturwandel in der Lausitz</div>
          <div className="even-areas">
            <img
              className="project-paragraph-img"
              src={imgProject}
              alt="Strukturwandel in der Lausitz"
            ></img>
            <div className="project-paragraph">
              Die Gesundheitsversorgung ist ein wesentlicher Standortfaktor in
              der Region. Mit unserem Netzwerk möchten wir die Versorgung
              langfristig sicherstellen und Gestaltungsprozesse unterstützen.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Strukturwandel;
