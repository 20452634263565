import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./assets/styles/App.css";
import Main from "./Main";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Contact from "./pages/Misc/Contact";
import Impressum from "./pages/Misc/Impressum";
import Datenschutz from "./pages/Misc/Datenschutz";
import Weiterbildung from "./pages/Projects/Weiterbildung";
import CaseManagment from "./pages/Projects/CaseManagment";
import Gesundheitskompetenz from "./pages/Projects/Gesundheitskompetenz";
import Digitalisierung from "./pages/Projects/Digitalisierung";
import Foerderprojekte from "./pages/Projects/Foerderprojekte";
import Strukturwandel from "./pages/Projects/Strukturwandel";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/aus-und-weiterbildung" element={<Weiterbildung />} />
            <Route path="/case-management" element={<CaseManagment />} />
            <Route
              path="/gesundheitskompetenz"
              element={<Gesundheitskompetenz />}
            />
            <Route path="/digitalisierung" element={<Digitalisierung />} />
            <Route path="/foerderprojekte" element={<Foerderprojekte />} />
            <Route path="/strukturwandel" element={<Strukturwandel />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
