import React from "react";
import { HashLink } from "react-router-hash-link";
import logoInstagram from "../assets/img/instagram.webp";
import logoLinkedIn from "../assets/img/linkedin.webp";

function Footer() {
  const YEAR = new Date().getFullYear();

  return (
    <footer>
      <div className="primary-footer">
        <div className="primary-footer-links">
          <a
            href="https://www.linkedin.com/company/%C3%A4rztenetz-s%C3%BCdbrandenburg-e-v/"
            target="_blank"
            rel="noreferrer noopener">
            <div className="footer-logo-links">
              <img src={logoLinkedIn} alt="LinkedIn_ANSB" />
            </div>
          </a>
          <a
            href="https://www.instagram.com/aerztenetzsuedbrandenburg/"
            target="_blank"
            rel="noreferrer noopener">
            <div className="footer-logo-links">
              <img src={logoInstagram} alt="Instagram_ANSB" />
            </div>
          </a>
        </div>
        <div className="footer-copyright">
          {"© " + YEAR + " Ärzte- und Gesundheitsnetz Südbrandenburg e.V."}
        </div>
        <div className="primary-footer-nav">
          <nav className="footer-nav">
            <HashLink to="/impressum">Impressum</HashLink>
            <HashLink to="/datenschutz">Datenschutz</HashLink>
            <HashLink to="/kontakt">Kontakt</HashLink>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
