import { React } from "react";
import { HashLink } from "react-router-hash-link";

function NavbarPopUp({ MenuIndex, onLinkClick }) {
  const ActiveMenu = () => {
    if (MenuIndex === 1) {
      return <NetworkMenu />;
    } else if (MenuIndex === 2) {
      return <ProjectMenu />;
    }
    return;
  };

  const NetworkMenu = () => {
    return (
      <div className="nav-modal">
        <HashLink
          className="nav-modal-link"
          to="/#der-verein"
          onClick={onLinkClick}
        >
          Der Verein
        </HashLink>
        <HashLink
          className="nav-modal-link"
          to="/#das-netzwerkbuero"
          onClick={onLinkClick}
        >
          Das Netzwerkbüro
        </HashLink>
        <HashLink
          className="nav-modal-link"
          to="/#die-mitglieder"
          onClick={onLinkClick}
        >
          Die Mitglieder
        </HashLink>
      </div>
    );
  };

  const ProjectMenu = () => {
    return (
      <div className="nav-modal">
        <HashLink
          className="nav-modal-link"
          to="/aus-und-weiterbildung"
          onClick={onLinkClick}
        >
          Aus- und Weiterbildung
        </HashLink>
        <HashLink
          className="nav-modal-link"
          to="/case-management"
          onClick={onLinkClick}
        >
          Case Management
        </HashLink>
        <HashLink
          className="nav-modal-link"
          to="/gesundheitskompetenz"
          onClick={onLinkClick}
        >
          Gesundheitskompetenz
        </HashLink>
        <HashLink
          className="nav-modal-link"
          to="/digitalisierung"
          onClick={onLinkClick}
        >
          Digitalisierung
        </HashLink>
        <HashLink
          className="nav-modal-link"
          to="/foerderprojekte"
          onClick={onLinkClick}
        >
          Förderprojekte
        </HashLink>
        <HashLink
          className="nav-modal-link"
          to="/strukturwandel"
          onClick={onLinkClick}
        >
          Strukturwandel
        </HashLink>
      </div>
    );
  };

  return <ActiveMenu />;
}

export default NavbarPopUp;
