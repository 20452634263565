import React, { useEffect } from "react";
import imgCaseManagment from "../../assets/img/case_management.webp";
import imgCaseManagment2 from "../../assets/img/case_management_2.webp";
import plakat from "../../assets/img/Projekt-Plakate 2.webp";
import flyer from "../../assets/img/Fyler.pdf";

function CaseManagment() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="project-wrapper">
      <div className="container">
        <div className="padding-block-700">
          <div className="project-container">
            <div className="project-heading">Case Management</div>
            <div className="project-paragraph">
              Das Case Management übernimmt die Aufgabe, Personen in
              hochkomplexen Problemlagen unabhängig mehrdimensional zu
              unterstützen, zu begleiten und im Rahmen der eigenen
              Gesundheitskompetenz selbstbestimmt und informiert zu fördern.
              Dazu übernimmt das Case Management die Steuerung Koordination von
              Leistungserbringer und arbeitet zudem sektorenübergreifend
              innerhalb eines Netzwerkes und im regionalen Versorgungsgefüge.
            </div>
            <div className="even-areas" data-type="cm">
              <img
                className="project-paragraph-img"
                src={imgCaseManagment}
                alt="caseManagement"
              ></img>
              <div>
                <div className="project-paragraph">
                  Wir, das Ärztenetz Südbrandenburg e.V. machen es uns zur
                  Projektaufgabe, dass schon vielfach erprobte Case Management
                  als Handlungskonzept in die Aufbau- und Ablauforganisation
                  unserer Netzwerkstrukturen zu implementieren und als
                  erweitertes Versorgungsangebot in der ambulanten
                  Daseinsversorgung zu integrieren. Dazu nutzen wir die
                  Synergien innerhalb unseres Netzwerkes und fördern
                  gleichzeitig die Zusammenarbeit, Vernetzung und Kooperationen
                  zwischen verschiedenste Leistungserbringer.
                </div>
                <p className="project-paragraph">
                  Am 01.01.2024 ist das Projekt "Patientenkoordinierung Lausitz"
                  gestartet. Das Ziel dieses Projektes ist es, die
                  Patientenversorgung in der Lausitz zu optimieren und
                  verschiedene Versorgungsangebote zu koordinieren bzw. zu
                  vernetzen.
                </p>
              </div>
            </div>
          </div>
          <div className="project-container">
            <div className="project-heading" data-type="cm">
              Projekt „Patientenkoordinierung Lausitz“
            </div>
            <div className="even-areas" data-type="cm2">
              <div>
                <p className="project-text">
                  Das Projekt bietet eine umfassende Terminkoordinierung für
                  ambulante Facharzttermine, stationäre Aufnahme und der
                  koordinierenden Entlassplanung in Zusammenarbeit mit dem
                  Krankenhaus. Der Schwerpunkt liegt auf der Gestaltung einer
                  effektiven Patientensteuerung, um den individuellen Bedarfen
                  und Bedürfnissen am Beispiel von multimorbiden Patienten
                  nachzukommen.
                </p>
                <br />
                <p className="project-text">
                  Besonderes Augenmerk wird auf die transparente und lückenlose
                  Nachvollziehbarkeit der weiteren Behandlung gelegt.
                  Kommunikation und Information werden durch moderne digitale
                  Lösungen gewährleistet, um kurze Wege zu ermöglichen. Das
                  Projekt verfolgt das übergeordnete Ziel, sektorenübergreifende
                  Versorgung und Vernetzung zu fördern. Durch die
                  „Patientenkoordinierung Lausitz“ werden
                </p>
              </div>
              <img
                className="project-paragraph-img"
                src={imgCaseManagment2}
                alt="caseManagement"
                data-type="cm2"
              ></img>
            </div>

            <ul className="project-list">
              <li className="project-list-item">
                eine Entlastung der Akteure und Akteurinnen im Gesundheitswesen
                geschaffen,
              </li>
              <li className="project-list-item">
                die Zufriedenheit der Patienten gesteigert,
              </li>
              <li className="project-list-item">Synergien effektiv genutzt,</li>
              <li className="project-list-item">
                regionale Versorgungsangebote gebündelt und
              </li>
              <li className="project-list-item">
                Netzwerkstrukturen in der Lausitz gefördert.
              </li>
            </ul>
            <p className="project-text">
              Sind Sie daran interessiert, Projektpartner zu werden?
            </p>
            <br />
            <p className="project-text">
              Wir freuen uns über ihre Kontaktanfrage unter
              kontakt@patientenkoordinierung-lausitz.de.
            </p>
            <br />
            <p className="project-text">
              Weitere Informationen zum Projekt „Patientenkoordinierung Lausitz“
              finden Sie auf unserem{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer noopener"
                href={flyer}
              >
                Patienten-Flyer
              </a>{" "}
              und dem{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer noopener"
                href={plakat}
              >
                Plakat
              </a>{" "}
              für Praxen und Kliniken.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseManagment;
