import { useState, useEffect, React } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import burgerMenu from "../assets/img/burgerMenu.webp";
import logo from "../assets/img/logo_ansb_text.webp";
import homeIcon from "../assets/img/home_icon.webp";
import chevronUp from "../assets/img/chevron_up.webp";
import chevronDown from "../assets/img/chevron_down.webp";
import NavbarPopUp from "./Navbar/NavbarPopUp";
import MobileDropDown from "./Navbar/MobileDropDown";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showNetworkMenu, setShowNetworkMenu] = useState(false);
  const [showProjectMenu, setShowProjectMenu] = useState(false);
  const [networkChevronDirection, setNetworkChevronDirection] = useState(0);
  const [projectChevronDirection, setProjectChevronDirection] = useState(0);

  const location = useLocation();
  const [staticNavbar, setStaticNavbar] = useState(false);

  useEffect(() => {
    // execute on location change
    if (location.pathname !== "/") {
      setStaticNavbar(true);
    } else {
      setStaticNavbar(false);
    }
    console.log("Location changed!", location.pathname);
  }, [location]);

  const onClickMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const onClickNetworkMenu = () => {
    closePopUps();
    setShowNetworkMenu(!showNetworkMenu);
  };

  const onClickProjectMenu = () => {
    closePopUps();
    setShowProjectMenu(!showProjectMenu);
  };

  const MobileMenu = () => {
    const NetworkHeader = {
      title: "Netzwerk",
    };

    const ProjectHeader = {
      title: "Projekte",
    };

    const NetworkLinks = [
      {
        title: "Der Verein",
        link: "/#der-verein",
      },
      {
        title: "Das Netzwerkbüro",
        link: "/#das-netzwerkbuero",
      },
      {
        title: "Die Mitglieder",
        link: "/#die-mitglieder",
      },
    ];

    const ProjectLinks = [
      {
        title: "Aus- und Weiterbildung",
        link: "/aus-und-weiterbildung",
      },
      {
        title: "Case Management",
        link: "/case-management",
      },
      {
        title: "Gesundheitskompetenz",
        link: "/gesundheitskompetenz",
      },
      {
        title: "Digitalisierung",
        link: "/digitalisierung",
      },
      {
        title: "Förderprojekte",
        link: "/foerderprojekte",
      },
      {
        title: "Strukturwandel",
        link: "/strukturwandel",
      },
    ];

    if (showMobileMenu) {
      return (
        <div className="nav-mobile-links">
          <MobileDropDown
            Header={NetworkHeader}
            Content={NetworkLinks}
            MobileMenuVisible={showMobileMenu}
            LinkClickFunction={onClickMobileMenu}
          />
          <HashLink
            className="nav-link"
            to="/#news"
            onClick={onClickMobileMenu}
          >
            Aktuelles
          </HashLink>
          <MobileDropDown
            Header={ProjectHeader}
            Content={ProjectLinks}
            MobileMenuVisible={showMobileMenu}
            LinkClickFunction={onClickMobileMenu}
          />
          <HashLink
            className="nav-link"
            to="/kontakt"
            onClick={onClickMobileMenu}
          >
            Kontakt
          </HashLink>
        </div>
      );
    }
    return;
  };

  const NavbarNetworkMenu = () => {
    if (showNetworkMenu) {
      setNetworkChevronDirection(chevronUp);
      return <NavbarPopUp MenuIndex={1} onLinkClick={onClickNetworkMenu} />;
    } else {
      setNetworkChevronDirection(chevronDown);
    }
  };

  const NavbarProjectMenu = () => {
    if (showProjectMenu) {
      setProjectChevronDirection(chevronUp);
      return <NavbarPopUp MenuIndex={2} onLinkClick={onClickProjectMenu} />;
    } else {
      setProjectChevronDirection(chevronDown);
    }
  };

  const closePopUps = () => {
    if (showNetworkMenu) {
      setShowNetworkMenu(false);
      setNetworkChevronDirection(chevronDown);
    }

    if (showProjectMenu) {
      setShowProjectMenu(false);
      setProjectChevronDirection(chevronDown);
    }

    if (showMobileMenu) {
      setShowMobileMenu(false);
    }
  };

  return (
    <header className="primary-header" data-type={staticNavbar ? "" : "fixed"}>
      <div className="nav">
        <HashLink to="/#top">
          <img className="nav-logo" src={logo} alt="logo" />
        </HashLink>
        <div className="nav-links">
          <div className="nav-extended-links">
            <span className="nav-link-dropdown">
              <div className="btn-icon" onClick={onClickNetworkMenu}>
                <div className="btn-icon-text" data-type="nav">
                  <span>Netzwerk</span>
                </div>
                <img src={networkChevronDirection} alt="Ausklappen"></img>
              </div>
              <NavbarNetworkMenu />
            </span>

            <HashLink className="nav-link" data-type="desktop" to="/#news">
              Aktuelles
            </HashLink>

            <span className="nav-link-dropdown">
              <div className="btn-icon" onClick={onClickProjectMenu}>
                <div className="btn-icon-text" data-type="nav">
                  <span>Projekte</span>
                </div>
                <img src={projectChevronDirection} alt="Ausklappen"></img>
              </div>
              <NavbarProjectMenu />
            </span>

            <HashLink
              className="btn"
              data-type="nav"
              to="/kontakt"
              onClick={closePopUps}
            >
              Kontakt
            </HashLink>
          </div>
          <div className="nav-mobile">
            <img src={burgerMenu} alt="navMenu" onClick={onClickMobileMenu} />
          </div>
        </div>
      </div>
      <MobileMenu />
    </header>
  );
}

export default Navbar;
