import React from "react";
import imgProject from "../../assets/img/aus_und_weiterbildung.webp";

function Weiterbildung() {
  return (
    <div className="project-wrapper">
      <div className="container">
        <div className="padding-block-700">
          <div className="project-heading">Aus- und Weiterbildung</div>
          <div className="even-areas">
            <img
              className="project-paragraph-img"
              src={imgProject}
              alt="Aus- und Weiterbildung"
            ></img>
            <div className="project-paragraph">
              Gemeinsam haben wir uns vorgenommen angehende MFA's und Ärzte zu
              unterstützen, ihre Aus- und Weiterbildung schnell,
              praxisorientiert und reibungslos zu absolvieren
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Weiterbildung;
