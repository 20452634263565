import React from "react";
import placeholder from "../assets/img/placeholder.webp";
import { HashLink } from "react-router-hash-link";
import imgProject1 from "../assets/img/aus_und_weiterbildung.webp";
import imgProject2 from "../assets/img/case_management.webp";
import imgProject3 from "../assets/img/gesundheitskompotenz.webp";
import imgProject4 from "../assets/img/digitalisierung.webp";
import imgProject5 from "../assets/img/foerderung.webp";
import imgProject6 from "../assets/img/strukurwandel.webp";

function Projects() {
  const projectList = [
    {
      title: "Aus- und Weiterbildung",
      link: "/aus-und-weiterbildung",
      img: imgProject1,
    },
    {
      title: "Case Management",
      link: "/case-management",
      img: imgProject2,
    },
    {
      title: "Gesundheitskompetenz",
      link: "/gesundheitskompetenz",
      img: imgProject3,
    },
    {
      title: "Strukturwandel",
      link: "/strukturwandel",
      img: imgProject6,
    },
    {
      title: "Förderprojekte",
      link: "/foerderprojekte",
      img: imgProject5,
    },
    {
      title: "Digitalisierung",
      link: "/digitalisierung",
      img: imgProject4,
    },
  ];

  const ProjectList = () => {
    const projectListComponent = [];
    projectList.forEach((project) => {
      projectListComponent.push(ProjectCard(project));
    });
    return <div className="project-card-list">{projectListComponent}</div>;
  };

  const ProjectCard = (project) => {
    return (
      <div className="project-card">
        <img
          className="project-card-img"
          src={project.img}
          alt="project_image"
        ></img>
        <div className="project-card-content">
          <div className="project-card-header">{project.title}</div>
          <HashLink className="btn" data-type="project" to={project.link}>
            Weitere Informationen
          </HashLink>
        </div>
      </div>
    );
  };

  return (
    <section id="projects">
      <div className="container" data-color="gray">
        <div className="padding-block-700">
          <div className="primary-heading">Projekte</div>
          {ProjectList()}
        </div>
      </div>
    </section>
  );
}

export default Projects;
